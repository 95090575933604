<template>
	<div>
		<page-title></page-title>
		<b-row class="mt-1">
			<b-col cols="12">
				<b-card no-body>
					<b-card-header>
						<h5 class="d-flex align-items-center">
							<feather-icon class="mr-50" icon="FilterIcon" size="16" />
							{{ $t("Filters") }}
						</h5>
					</b-card-header>
					<b-card-body>
						<b-row>
							<b-col cols="4">
								<p style="margin-bottom: 0.5rem">{{ $t("Choose a date") }}</p>
								<flat-pickr
									v-model="filterValues.add_time"
									:config="{
										wrap: true,
										time_24hr: true,
										mode: 'range',
										locale: $i18n.locale === 'cn' ? 'zh' : 'en',
									}"
									class="form-control"
								/>
							</b-col>
							<b-col cols="4">
								<p style="margin-bottom: 0.5rem">{{ $t("Plan Name") }}</p>
								<v-select v-model="filterValues.PLANNAME" :options="filterOptions.PLANNAME" :placeholder="$t('No filter')" class="w-100" />
							</b-col>
							<b-col cols="4">
								<p style="margin-bottom: 0.5rem">{{ $t("Inspector") }}</p>
								<v-select v-model="filterValues.user_name" :options="filterOptions.user_name" :placeholder="$t('No filter')" class="w-100" />
							</b-col>
						</b-row>
					</b-card-body>
				</b-card>
			</b-col>
		</b-row>
		<b-row>
			<!-- Table Container Card -->
			<b-col cols="12">
				<b-card no-body>
					<div class="m-2">
						<!-- Table Top -->
						<b-row>
							<!-- Per Page -->
							<b-col class="d-flex align-items-center justify-content-start mb-1 mb-md-0" cols="12" md="6">
								<label style="margin: 0.5rem 0">{{ $t("Show") }}</label>
								<v-select v-model="perPage" :clearable="false" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" class="per-page-selector d-inline-block mx-50" size="sm" />
								<label style="margin: 0.5rem 0">{{ $t("Entries") }}</label>
							</b-col>

							<!-- Search -->
							<b-col cols="12" md="6">
								<div class="d-flex align-middle justify-content-end h-100" style="padding: 0.3rem 0">
									<download-excel :fetch="downloadData" :fields="exportExcelHeaders(tableColumns)" name="下载查询数据.xls" type="xls">
										<b-button :disabled="isBusy || download_loading" size="sm" variant="success">
											<b-spinner v-if="download_loading" class="mr-50" small></b-spinner>
											<feather-icon v-else class="mr-50" icon="DownloadIcon" />
											下载表格
										</b-button>
									</download-excel>
									<b-button :disabled="isBusy || download_report_loading" class="ml-1" size="sm" variant="warning" @click="downloadExcel">
										<b-spinner v-if="download_report_loading" class="mr-50" small></b-spinner>
										<feather-icon v-else class="mr-50" icon="DownloadIcon" />
										下载报告
									</b-button>
								</div>
							</b-col>
						</b-row>
					</div>

					<b-table
						ref="refInspectionListTable"
						:busy="isBusy"
						:empty-text="$t('No Data')"
						:fields="tableColumns"
						:items="fetchInspections"
						:sort-by.sync="sortBy"
						:sort-desc.sync="isSortDirDesc"
						bordered
						class="text-center position-relative"
						hover
						primary-key="inspection_id"
						responsive
						show-empty
						small
						striped
					>
						<template v-slot:table-busy>
							<div class="text-center my-2">
								<b-spinner class="align-middle mr-1"></b-spinner>
								<strong>{{ $t("Loading") }}...</strong>
							</div>
						</template>
						<!-- Column: Index -->
						<template #cell(index)="data">
							<div class="text-nowrap">
								<span class="align-text-top text-capitalize">
									{{ data.index + 1 }}
								</span>
							</div>
						</template>
						<template #cell(add_time)="data">
							<div class="text-nowrap">
								<span class="align-text-top text-capitalize">
									{{ momentFmt(data.item.add_time, "l") }}
								</span>
							</div>
						</template>
						<!-- Column: Index -->
						<!--						<template #cell(actions)>-->
						<!--							<b-button class="btn-icon" size="sm" variant="warning"-->
						<!--							>-->
						<!--								<feather-icon icon="MoreHorizontalIcon"/>-->
						<!--							</b-button>-->
						<!--						</template>-->
					</b-table>
					<div class="mx-2 mb-2">
						<b-row>
							<b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
								<span class="text-muted">
									{{
										`
                                    ${$t("Show")}
                                    ${dataMeta.from}
                                    ${$t("to")}
                                    ${dataMeta.to}
                                    ${$t("of")}
                                    ${total}
                                    ${$t("Entries")}
                                    `
									}}
								</span>
							</b-col>
							<!-- Pagination -->
							<b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">
								<b-pagination v-model="currentPage" :per-page="perPage" :total-rows="total" hide-goto-end-buttons size="sm"></b-pagination>
							</b-col>
						</b-row>
					</div>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>
	import { momentFmt, momentJs } from "@/libs/utils/moment";
	import vSelect from "vue-select";
	import JsonExcel from "vue-json-excel";
	import flatPickr from "vue-flatpickr-component";
	import zh from "flatpickr/dist/l10n/zh.js";
	import Ripple from "vue-ripple-directive";
	import { computed, onMounted, reactive, toRefs, watch } from "@vue/composition-api";
	import { showToast } from "@/libs/utils/showToast";
	import { downloadInspectionsExcel, getInspections, getInspectionsOptions } from "@/api/front";
	import PageTitle from "@/views/layout/PageTitle";
	import { routerParams } from "@/libs/utils/routerParams";
	import { t } from "@core/libs/i18n/utils";

	export default {
		directives: {
			Ripple,
		},
		components: {
			PageTitle,
			vSelect,
			flatPickr,
			downloadExcel: JsonExcel,
		},
		setup() {
			const event = reactive({
				project_id: computed(() => {
					return routerParams("project_id");
				}),
				filterOptions: {},
				filterValues: {
					PLANNAME: null,
					user_name: null,
					add_time: momentJs().format(),
				},
				isEditInspectionSidebarActive: false,
				refInspectionListTable: null,
				perPage: 20,
				searchQuery: "",
				tableColumns: [
					{
						label: "#",
						key: "index",
					},
					{
						label: "计划名称",
						key: "PLANNAME",
						sortable: true,
					},
					{
						label: "设备名称",
						key: "CASENAME",
						sortable: true,
					},
					{
						label: "巡检项",
						key: "CHECKITEM",
						sortable: true,
					},
					{
						label: "位置",
						key: "POSIRANGE",
						sortable: true,
					},
					{
						label: "巡检时间",
						key: "add_time",
						sortable: true,
					},
					{
						label: "巡检结果",
						key: "itemresult",
					},
					{
						label: "巡检人",
						key: "user_name",
						sortable: true,
					},
					// {
					// 	label: "操作",
					// 	key: "actions",
					// },
				],
				total: 0,
				currentPage: 1,
				perPageOptions: [10, 20, 50, 100],
				sortBy: "add_time",
				isSortDirDesc: true,
				dataMeta: computed(() => {
					const localItemsCount = event.refInspectionListTable ? event.refInspectionListTable.localItems.length : 0;
					return {
						from: event.perPage * (event.currentPage - 1) + (localItemsCount ? 1 : 0),
						to: event.perPage * (event.currentPage - 1) + localItemsCount,
						of: event.total,
					};
				}),
				isBusy: false,
				download_loading: false,
				download_report_loading: false,
			});

			watch([() => event.currentPage, () => event.perPage, () => event.isEditInspectionSidebarActive, () => event.searchQuery], () => {
				if (!event.isEditInspectionSidebarActive) {
					refetchData();
				}
			});

			watch(
				event.filterValues,
				() => {
					console.log("change", event.filterValues);
					refetchData();
				},
				"deep"
			);

			onMounted(() => {
				getOptions();
			});

			const refetchData = () => {
				event.refInspectionListTable.refresh();
			};
			const fetchInspections = (ctx, callback) => {
				event.isBusy = true;
				const filters = getFilters();
				const params = {
					project_id: event.project_id,
					pagesize: event.perPage,
					page: event.currentPage,
					sortBy: event.sortBy,
					sortDesc: event.isSortDirDesc ? 1 : 0,
					...filters,
				};
				getList(params)
					.then((response) => {
						const { list, total } = response.data;
						callback(list);
						event.total = total;
						if (!total) {
							showToast(null, "提示", "未查询到数据");
						}
						event.isBusy = false;
					})
					.catch((error) => {
						event.isBusy = false;
						showToast(error);
					});
			};
			/**
			 * 获取列表数据
			 */
			const getList = (params) => {
				return getInspections({
					project_id: event.project_id,
					...params,
				});
			};
			/**
			 * 获取参数
			 */
			const getOptions = () => {
				getInspectionsOptions({
					project_id: event.project_id,
				})
					.then((response) => {
						event.filterOptions = response.data;
					})
					.catch((error) => {
						showToast(error);
					});
			};
			/**
			 * 处理导出数据的头部
			 * @param fields
			 */
			const exportExcelHeaders = (fields) => {
				let headers = {};
				fields.map((item) => {
					if (item.key !== "index" && item.key !== "actions") {
						let header = t(item.label) + "(" + item.key + ")";
						headers[header] = item.key;
					}
				});
				return headers;
			};
			/**
			 * 获取筛选条件
			 */
			const getFilters = () => {
				let filters = {};
				for (const Key in event.filterValues) {
					let Value = event.filterValues[Key];
					if (!!Value) {
						filters[Key] = Value;
						if (Key === "add_time") {
							Value = Value.replaceAll(" 至 ", ",").replaceAll(" to ", ",");
							const timeArr = Value.split(",");
							const start = momentJs(timeArr[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss");
							const end = momentJs(timeArr[1] || timeArr[0])
								.endOf("day")
								.format("YYYY-MM-DD HH:mm:ss");
							filters[Key] = "(" + start + "," + end + ")";
						}
					}
				}
				return filters;
			};
			/**
			 * 下载数据
			 * @returns {Promise<*>}
			 */
			const downloadData = async () => {
				showToast(null, "提示", "正在准备需要下载的数据");
				event.download_loading = true;
				const filters = getFilters();
				const params = {
					sortBy: event.sortBy,
					sortDesc: event.isSortDirDesc ? 1 : 0,
					...filters,
				};
				const res = await getList(params);
				event.download_loading = false;
				if (!res.data.total) {
					showToast(null, "提示", "暂无数据可以下载");
				}
				showToast(null, "成功", "表格已下载");
				return res.data.list;
			};

			const downloadExcel = async () => {
				showToast(null, "提示", "正在准备需要下载的数据");
				event.download_report_loading = true;
				const filters = getFilters();
				const params = {
					project_id: event.project_id,
					...filters,
				};
				downloadInspectionsExcel(params)
					.then((response) => {
						console.log("fileUrl", response.data);
						window.location.href = "/" + response.data;
						showToast(null, "成功", "报告已下载");
						event.download_report_loading = false;
					})
					.catch((error) => {
						showToast(error);
						event.download_report_loading = false;
					});
			};

			return {
				...toRefs(event),
				fetchInspections,
				exportExcelHeaders,
				downloadData,
				zh,
				downloadExcel,
				momentFmt,
			};
		},
	};
</script>

<style lang="scss">
	/* .per-page-selector {
width: 90px;
}

.b-form-btn-label-control.dropdown.b-form-datepicker.form-control
label.form-control {
padding: 0.3rem 1rem;
} */
</style>

<style lang="scss">
	@import "../../../../@core/scss/vue/libs/vue-flatpicker";
	@import "../../../../@core/scss/vue/libs/vue-select";
</style>
